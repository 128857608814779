
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { UnitDetail } from '@/types/product'

@Component({
  name: 'UnitAdd'
})
export default class extends Vue {
  private title = '新增计量单位'
  private info: UnitDetail = {
    unitName: '',
    sdescribe: ''
  }

  private rules = {
    unitName: [
      { required: true, message: '请输入单位名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get unitId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.unitId) {
      this.title = '编辑计量单位'
      this.getDetail()
    }
  }

  // 计量单位详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductUnitByUnitId, {
      unitId: this.unitId
    }).then((res) => {
      this.info = res
    })
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addUnit()
      }
    })
  }

  // 用户详情
  addUnit () {
    this.submitShow = true
    const url = this.unitId
      ? this.$apis.product.updateProductUnit
      : this.$apis.product.insertProductUnit
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ path: '/productList/baseSet/unit' })
    }).finally(() => {
      this.submitShow = false
    })
  }
}
